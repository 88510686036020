<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView } from '@cargill/shared'
import service from '../api/takeUpHviExtractionService'
import downloadFile from 'js-file-download'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey:
            'controlTower.pages.takeUpHviExtraction.messages.packingListExtract',
          onClick: this.packingListExtract,
          iconColor: '#959DB5',
          faIcon: 'fa-list'
        },
        {
          canShow: true,
          titleKey:
            'controlTower.pages.takeUpHviExtraction.messages.hviExtract',
          onClick: this.hviExtract,
          iconColor: '#959DB5',
          faIcon: 'fa-table'
        },
        {
          canShow: true,
          titleKey: 'controlTower.misc.exportCsv',
          onClick: () => this.exportCsv(false),
          iconColor: '#959DB5',
          faIcon: 'fa-file-csv'
        }
      ]
      return meta
    },
    async packingListExtract() {
      const tabName = this.$t(
        'controlTower.pages.takeUpHviExtraction.messages.packingListExtract'
      )
      const result = await service.packingListExtract(tabName)
      downloadFile(result.data, result.filename ?? `${tabName}.xlsx`)
    },
    async hviExtract() {
      const tabName = this.$t(
        'controlTower.pages.takeUpHviExtraction.messages.hviExtract'
      )
      const result = await service.hviExtract(tabName)
      downloadFile(result.data, result.filename ?? `${tabName}.xlsx`)
    },
    async exportCsv() {
      const fileName = this.$t('controlTower.pages.takeUpHviExtraction.title')
      const data = await service.exportCsv()
      downloadFile(data, `${fileName}.zip`)
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
