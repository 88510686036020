import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpHviExtraction',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.weightUnity.options = ctx.weightUnityOptions
}

service.packingListExtract = async (tabName) => {
  try {
    const tabNameQuery = `tabName=${tabName}`
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `${service.lastAppliedFilter}`
    const baseUrl = '/api/controltower/takeUpHviExtraction/packingListExtract'
    const url = filter
      ? `${baseUrl}?${filter}&${tabNameQuery}`
      : `${baseUrl}?${tabNameQuery}`
    const response = await api.get(url, {
      responseType: 'blob'
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.hviExtract = async (tabName) => {
  try {
    const tabNameQuery = `tabName=${tabName}`
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `${service.lastAppliedFilter}`
    const baseUrl = '/api/controltower/takeUpHviExtraction/hviExtract'
    const url = filter
      ? `${baseUrl}?${filter}&${tabNameQuery}`
      : `${baseUrl}?${tabNameQuery}`
    const response = await api.get(url, {
      responseType: 'blob'
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")
  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null
  return decodeURIComponent(filename)
}

service.exportCsv = async () => {
  const filter = _.isEmpty(service.lastAppliedFilter)
    ? ''
    : `${service.lastAppliedFilter}`
  const baseUrl = '/api/controltower/takeUpHviExtraction/exportCsv'
  const url = filter ? `${baseUrl}?${filter}` : `${baseUrl}`
  try {
    const response = await api.get(url, {
      responseType: 'blob'
    })
    return response?.data
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

export default service
